import useSWR, { useSWRConfig } from "swr";
import {
  fetchAvailableFlowEvents,
  fetchFlow,
  fetchFlows,
  fetchAction,
  fetchActions,
  pullCustomEvent,
  saveFlow,
  updateAction,
} from "@/api/flows/index";
import useSWRMutation from "swr/mutation";
import type {
  CreateFlowDto,
  ImportPlatformEventParams,
  ActionDescriptionUpdateDto,
  ActionFilters,
  UpdateFlowDto,
} from "@/api/flows/types";
import type { Pagination } from "@/api/types";

export const useFlowEvents = () =>
  useSWR("flow-events", fetchAvailableFlowEvents, {
    fallbackData: [],
  });

export const useFlowEventImport = () => {
  const { mutate } = useSWRConfig();
  return useSWRMutation("flow-events", async (_, { arg: params }: { arg: ImportPlatformEventParams }) => {
    await pullCustomEvent(params);
    await mutate(["salesforce-object", params.name]);
  });
};

export const useActions = (filters: ActionFilters = {}) =>
  useSWR(["actions", filters], ([_, filters]) => fetchActions(filters), {
    fallbackData: [],
  });

export const useAction = (key: string) => useSWR(["actions", key], ([_, key]) => fetchAction(key));

export const useActionMutation = (key: string) =>
  useSWRMutation(key ? ["actions", key] : "actions", (_, { arg: dto }: { arg: ActionDescriptionUpdateDto }) =>
    updateAction(dto)
  );

export const useFlows = (filters: Pagination) =>
  useSWR(["flows", filters], ([_, filters]) => fetchFlows(filters), {
    fallbackData: { data: [] },
  });

export const useFlow = (id: string) => useSWR(["flows", id], ([_, key]) => fetchFlow(key));

export const useFlowMutation = (id: string) =>
  useSWRMutation(id ? ["flows", id] : "flows", (_, { arg: dto }: { arg: CreateFlowDto | UpdateFlowDto }) =>
    saveFlow(dto)
  );
