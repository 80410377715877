import { Alert, Button, Col, Divider, Form, Modal, Row, Switch, Tooltip } from "antd";
import { DownloadOutlined, LoadingOutlined } from "@ant-design/icons";
import { downloadSpreadsheet } from "@/api/on-demand-reports";
import { IconButton } from "@/components/common/IconButton/IconButton";
import type { DynamicFieldsByQuery, OnDemandReport } from "@/api/on-demand-reports/types";
import { useBoolean } from "ahooks";
import { Close } from "@/components/common/Icons";
import React, { Fragment, useCallback, useState } from "react";
import type { QueryParamType } from "@/api/openplay-queries/types";
import { Field } from "@/components/common/Form/Field";
import { useDownload } from "@/stores/downloads";
import { SalesforceQueryBuilder } from "@/components/common/SalesforceQuery/SalesforceQueryBuilder";
import type { Logic } from "@/api/types";
import { getCommonParameters, getSourcedParameters, hasDynamicFields } from "./utils";
import { ParameterField, TenantField } from "./components";

type Props = {
  report: OnDemandReport;
  iconOnly?: boolean;
};

const opts = {
  message:
    "The spreadsheet is being generated. You can navigate to other pages, and the download will start automatically once ready.",
  noContentMessage: "The are no records to include into the file",
  acceptedMessage:
    "The spreadsheet generation is taking longer than expected. You will receive an email once the file is ready",
};

type FormValues = {
  openplay: DynamicFieldsByQuery;
  salesforce: Record<string, Logic>;
};

export const DownloadSpreadsheetButton = ({ report, iconOnly }: Props) => {
  const { downloading, trigger } = useDownload({
    key: `on-demand-report-${report.id}`,
    fetcher: downloadSpreadsheet,
    options: opts,
  });

  const [form] = Form.useForm<FormValues>();

  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);

  const openplayQueriesWithParameters = report.openplayQueries.filter((query) =>
    hasDynamicFields(query.openplayQuery)
  );

  const commonParameters = getCommonParameters(openplayQueriesWithParameters);

  const [grouped, setGrouped] = useState(true);

  const handleOpen = () => {
    if (openplayQueriesWithParameters.length > 0 || report.queries.length > 0) {
      return showModal();
    }
    trigger(report.id);
  };

  const handleDownload = (values: FormValues) => {
    hideModal();
    form.resetFields();
    return trigger(report.id, {
      dynamicFieldsByOpenplayQuery: values.openplay,
      variationsBySalesforceQuery: values.salesforce,
    });
  };

  const handleCancel = useCallback(() => {
    hideModal();
    form.resetFields();
  }, [form, hideModal]);

  return (
    <>
      {iconOnly ? (
        <Tooltip title="Generate Report">
          <IconButton onClick={handleOpen} disabled={downloading}>
            {downloading ? <LoadingOutlined /> : <DownloadOutlined />}
          </IconButton>
        </Tooltip>
      ) : (
        <Button icon={<DownloadOutlined />} onClick={handleOpen} loading={downloading}>
          Generate Report
        </Button>
      )}
      <Modal
        open={isModalOpen}
        title="Generate Report"
        footer={
          <>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button type="primary" loading={downloading} onClick={form.submit}>
              Download
            </Button>
          </>
        }
        onCancel={handleCancel}
        closeIcon={<Close />}
        width={900}
      >
        <Form form={form} onFinish={handleDownload}>
          {report.queries.length > 0 && (
            <>
              <Alert message="Add extra conditions to Salesforce queries where needed" type="info" />
              {report.queries.map((query) => (
                <Fragment key={query.id}>
                  <Divider>{query.name?.trim() || `Query #${report.queries.indexOf(query) + 1}`}</Divider>
                  <Field name={["salesforce", query.id]}>
                    <SalesforceQueryBuilder from={query.from} />
                  </Field>
                </Fragment>
              ))}
            </>
          )}
          {openplayQueriesWithParameters.length > 0 && (
            <>
              <Alert message="Please, specify dynamic parameters for OpenPlay queries" type="info" />
              {commonParameters.length > 0 && (
                <div style={{ marginTop: 20, display: "flex", alignItems: "center", gap: 16 }}>
                  <Switch id="param-grouping-switch" checked={grouped} onChange={setGrouped} />
                  <label className="field-label" htmlFor="param-grouping-switch">
                    Group Common Parameters
                  </label>
                </div>
              )}
              {commonParameters.length > 0 && grouped && (
                <div>
                  <Divider>Common Parameters</Divider>
                  <Row gutter={[16, 16]}>
                    {commonParameters.map((param) => {
                      const [name, type] = param.split("::");

                      if (type === "tenant") {
                        return (
                          <Col span={12} key={param}>
                            <TenantField name={["openplay", "common", "tenant"]} />
                          </Col>
                        );
                      }

                      return (
                        <Col span={12} key={param}>
                          <ParameterField
                            name={["openplay", "common", "parameters", name]}
                            label={name.replaceAll("_", " ")}
                            type={type as QueryParamType}
                          />
                        </Col>
                      );
                    })}
                  </Row>
                </div>
              )}
              {openplayQueriesWithParameters
                .map((query) => {
                  const sourcedParameters = getSourcedParameters(query);
                  const showTenantField =
                    query.openplayQuery.defaultTenant && (!grouped || !commonParameters.includes("::tenant"));
                  const parametersToShow = Object.entries(query.openplayQuery.parameters ?? {}).filter(
                    ([name, { type }]) => !grouped || !commonParameters.includes(`${name}::${type}`)
                  );

                  if (!showTenantField && parametersToShow.length === 0) {
                    return null;
                  }

                  return (
                    <Fragment key={query.id}>
                      <Divider>
                        {query.name?.trim() ||
                          `Query #${report.openplayQueries.indexOf(query) + 1} (${query.openplayQuery.name})`}
                      </Divider>
                      <Row gutter={[16, 16]}>
                        {showTenantField && (
                          <Col span={12}>
                            <TenantField name={["openplay", query.id, "tenant"]} />
                          </Col>
                        )}
                        {parametersToShow.map(([name, { type }]) => {
                          const isSourced = sourcedParameters.includes(name);
                          return (
                            <Col span={12} key={name}>
                              <ParameterField
                                name={["openplay", query.id, "parameters", name]}
                                label={name.replaceAll("_", " ")}
                                type={type}
                                isSourced={isSourced}
                              />
                            </Col>
                          );
                        })}
                      </Row>
                    </Fragment>
                  );
                })
                .filter(Boolean)}
            </>
          )}
        </Form>
      </Modal>
    </>
  );
};
