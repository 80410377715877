import { api } from "@/api/core";
import type { CreateStorageDto, Storage, UpdateStorageDto } from "@/api/storages/types";

const url = "/api/storages";

export const fetchStorages = async () => api.get<any, Storage[]>(url);

export const createStorage = (fields: CreateStorageDto) => api.post<any, Storage>(url, fields);

export const updateStorage = (fields: UpdateStorageDto) => api.put<any, Storage>(url, fields);

export const saveStorage = (fields: CreateStorageDto | UpdateStorageDto) =>
  "id" in fields ? updateStorage(fields) : createStorage(fields);

export const testStorage = (fields: CreateStorageDto) =>
  api.post<any, { success: boolean; reason?: string }>(`${url}/test`, fields);
