import { Button, Col, Form, Popconfirm, Row, Space } from "antd";
import { HiddenField } from "@/components/common/HiddenField";
import { Box } from "@/components/common/Box/Box";
import { SpreadsheetFilenameField } from "@/components/Reports/Details/SpreadsheetFilenameField";
import { FieldGroup } from "@/components/common/Form/FieldGroup";
import { SpreadsheetTabs } from "@/components/Reports/Details/SpreadsheetTabs";
import { FullWidthButton } from "@/components/common/FullWidthButton/FullWidthButton";
import { Plus } from "@/components/common/Icons";
import { SpreadsheetFormProvider } from "@/components/Spreadsheets/SpreadsheetForm/context";
import type { Variables } from "@/components/common/RichTextEditor/Variables/types";
import { SpreadsheetTemplateField } from "@/components/Spreadsheets/SpreadsheetForm/SpreadsheetTemplateField";
import { Field } from "@/components/common/Form/Field";
import { StorageEditor } from "@/components/Storage/StorageEditor";

type Props = {
  entity: "report" | "email-alert" | "on-demand-report";
  readOnly: boolean;
  variables: Variables;
  removable?: boolean;
};

export const SpreadsheetForm = ({ readOnly, entity, variables, removable = true }: Props) => {
  const form = Form.useFormInstance();

  const hasSpreadsheet = Form.useWatch<boolean>(["hasSpreadsheet"]) ?? false;

  const handleAdd = () => {
    form.setFieldValue("hasSpreadsheet", true);
    form.setFieldValue("spreadsheet", { tabs: [] });
  };

  const handleRemove = () => {
    form.setFieldValue("hasSpreadsheet", false);
    form.setFieldValue("spreadsheet", null);
  };

  const template = Form.useWatch(["spreadsheet", "template"]) ?? null;

  return (
    <SpreadsheetFormProvider entity={entity}>
      <HiddenField name="hasSpreadsheet" />
      {hasSpreadsheet ? (
        <Row gutter={[16, 16]}>
          <HiddenField name={["spreadsheet", "id"]} />
          <Col span={24}>
            <Box readOnly={readOnly}>
              <Row gutter={[16, 16]} align="middle">
                <Col span={12}>
                  <SpreadsheetFilenameField variables={variables} />
                </Col>
                <Col span={12}>{entity === "email-alert" && <SpreadsheetTemplateField />}</Col>
                <Col span={12}>
                  <Field
                    label="Storage"
                    name={["spreadsheet", "storage", "id"]}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    initialValue={null}
                  >
                    <StorageEditor />
                  </Field>
                </Col>
              </Row>
            </Box>
          </Col>
          {!template && (
            <Col span={24}>
              <FieldGroup prefix="spreadsheet">
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <SpreadsheetTabs disabled={readOnly} />
                  </Col>
                </Row>
              </FieldGroup>
            </Col>
          )}
          {removable && (
            <Col span={24}>
              <Space size={8}>
                <Popconfirm
                  title="Are you sure you want to remove the spreadsheet?"
                  onConfirm={handleRemove}
                  okText="Yes"
                  disabled={readOnly}
                >
                  <Button size="small" danger disabled={readOnly}>
                    Remove Spreadsheet
                  </Button>
                </Popconfirm>
              </Space>
            </Col>
          )}
        </Row>
      ) : (
        <FullWidthButton label="Add Spreadsheet" icon={Plus} onClick={handleAdd} disabled={readOnly} />
      )}
    </SpreadsheetFormProvider>
  );
};
