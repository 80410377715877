import type { OnDemandReportOpenplayQuery } from "@/api/on-demand-reports/types";
import { uniq } from "ramda";
import type { OpenplayQuery } from "@/api/openplay-queries/types";

export const hasDynamicFields = (query: OpenplayQuery) =>
  !!query.defaultTenant || Object.keys(query.parameters ?? {}).length > 0;

export const getSourcedParameters = (query: OnDemandReportOpenplayQuery) =>
  uniq(query.parameterSources.map(({ parameter }) => parameter).filter(Boolean));

class Counter extends Map<string, number> {
  add(key: string) {
    if (this.has(key)) {
      this.set(key, this.get(key) + 1);
    } else {
      this.set(key, 1);
    }
  }
}

export const getCommonParameters = (queries: OnDemandReportOpenplayQuery[]) => {
  if (queries.length <= 1) {
    return [];
  }

  const counts = new Counter();

  for (const query of queries) {
    if (query.openplayQuery.defaultTenant) {
      counts.add("::tenant");
    }
    const sourcedParameters = getSourcedParameters(query);
    Object.entries(query.openplayQuery.parameters ?? {}).forEach(([name, { type }]) => {
      if (!sourcedParameters.includes(name)) {
        counts.add(`${name}::${type}`);
      }
    });
  }

  return [...counts.entries()].filter(([, count]) => count > 1).map(([name]) => name);
};
