import "./OpenplayQueryEditor.less";
import Icon from "@ant-design/icons";
import { DropdownArrow } from "@/components/common/Icons";
import { Button, Form, Popconfirm, Select } from "antd";
import { OpenplayQueryModal } from "./OpenplayQueryModal";
import { useBoolean } from "ahooks";
import { omit } from "ramda";
import type { OpenplayQuery } from "@/api/openplay-queries/types";
import { useAuth } from "@/utils";
import { PermissionAction, PermissionSubject } from "@/api/users/types";

type Props = {
  value?: string;
  onChange?: (value?: string) => void;
  removable?: boolean;
  isLoading?: boolean;
  queries: OpenplayQuery[];
};

export const OpenplayQueryEditor = ({
  value,
  onChange,
  removable = false,
  queries,
  isLoading = false,
}: Props) => {
  const [form] = Form.useForm();

  const [isOpen, { setTrue: open, setFalse: close }] = useBoolean(false);

  const selectedQuery = queries.find((entry) => entry.id === value);

  const handleClose = () => {
    form.resetFields();
    close();
  };

  const handleEdit = () => {
    form.setFieldsValue(selectedQuery);
    open();
  };

  const handleDuplicate = () => {
    form.setFieldsValue(omit(["id"], selectedQuery));
    open();
  };

  const { ability } = useAuth();

  const canEdit = ability.can(PermissionAction.Update, PermissionSubject.OpenplayQuery);
  const canCreate = ability.can(PermissionAction.Create, PermissionSubject.OpenplayQuery);

  return (
    <div className="openplay-query-editor">
      <Select
        className="openplay-query-editor__select"
        placeholder="Select Existing or Create New"
        allowClear={removable}
        loading={isLoading}
        disabled={isLoading || !removable}
        options={queries.map((entry) => ({ value: entry.id, label: entry.name }))}
        suffixIcon={<Icon component={DropdownArrow} />}
        value={value}
        onChange={onChange}
      />
      {selectedQuery ? (
        <>
          <Popconfirm
            title="Editing existing OpenPlay query may impact other reports that reference it"
            okText="Edit Anyway"
            onConfirm={handleEdit}
            overlayStyle={{ maxWidth: 300 }}
            disabled={!canEdit}
          >
            <Button size="small" disabled={!canEdit}>
              Edit
            </Button>
          </Popconfirm>
          {removable && (
            <Button size="small" disabled={!canCreate} onClick={handleDuplicate}>
              Duplicate & Edit
            </Button>
          )}
        </>
      ) : (
        <Button disabled={!canCreate} type="primary" size="small" onClick={open}>
          Create
        </Button>
      )}
      <OpenplayQueryModal open={isOpen} form={form} onClose={handleClose} onFinish={onChange} />
    </div>
  );
};
