import type { Status } from "@/utils";
import type { SalesforceObject } from "@/api/salesforce-objects/types";

export type FlowAction = {
  id: string;
  order: number;
  type: string;
  params: Record<string, any>;
};

export enum FlowTriggerType {
  Bynder = "bynder",
  Salesforce = "salesforce",
}

export enum ChangeType {
  Create = "create",
  Update = "update",
}

export enum FlowEventType {
  ChangeEvent = "change-event",
  PlatformEvent = "platform-event",
}

export type FlowEvent = {
  name: string;
  label: string;
  entity: string;
  type: FlowEventType;
  deletedAt?: string;
  eventObject?: SalesforceObject;
};

export type Flow = {
  id: string;
  name: string;
  triggerType: FlowTriggerType;
  description?: string;
  status: Status;
  deactivationReason?: string;
  eventName?: string;
  event?: FlowEvent;
  bynderEventType?: string;
  changeType?: ChangeType;
  updateCriteria?: Record<string, unknown>;
  filter?: Record<string, unknown>;
  actions: FlowAction[];
};

export type CreateFlowDto = Omit<Flow, "id" | "alert" | "event">;
export type UpdateFlowDto = Pick<Flow, "id"> & Partial<CreateFlowDto>;

export type ImportPlatformEventParams = { name: string; refresh?: boolean };

export type ActionParam = {
  name: string;
  label: string;
  options: { value: string; label: string; sourceObject?: string }[];
};

export type Action = {
  key: string;
  label: string;
  params: ActionParam[];
  sourceObject?: string;
  details?: string;
  description?: string;
};

export type ActionFilters = {
  sourceObject?: string;
  includeParams?: boolean;
  triggerType?: FlowTriggerType;
};

export type ActionDescriptionUpdateDto = {
  key: string;
  description?: string;
};
