import axios from "axios";
import { openNotification } from "@/components/common/CommonNotification";
import { ERROR_MESSAGES } from "./messages";
import { stringify } from "qs";
import { mutate } from "swr";

export const api = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json; charset=UTF-8",
  },
  paramsSerializer: stringify,
});

const ENDPOINTS_WITHOUT_REFRESH = [`/api/users/me`];

api.interceptors.response.use(
  (response) => {
    if (response.config.responseType === "blob") {
      return response;
    }
    return response.data;
  },
  async (error) => {
    if (error?.response?.status === 401) {
      if (!ENDPOINTS_WITHOUT_REFRESH.includes(error.response.config.url)) {
        await mutate("me");
      }
    } else if (
      !ERROR_MESSAGES.hasOwnProperty(error?.response?.data.errorCode) &&
      error?.response?.status !== 404 &&
      error?.response?.status !== 403
    ) {
      openNotification({
        type: "error",
        message: error.message,
        description: error?.response?.data?.message ?? error?.message,
      });
    }
    if (error?.response?.data) {
      return Promise.reject(error?.response?.data);
    } else {
      return Promise.reject(error);
    }
  }
);
