import { Field } from "@/components/common/Form/Field";
import type { SelectProps } from "antd";
import { Col, Input, Row, Select } from "antd";
import { toOptions } from "@/utils/toOptions";
import { fetchEmailSenderDomains } from "@/api/email-sender-domains";
import useSWR from "swr";

type SenderDomainPickerProps = Pick<SelectProps, "value" | "onChange">;

export const SenderDomainPicker = ({ value, onChange }: SenderDomainPickerProps) => {
  const { data: domains, isLoading } = useSWR("sender-domains", fetchEmailSenderDomains, {
    fallbackData: [],
  });
  return (
    <Select
      value={value}
      onChange={onChange}
      loading={isLoading}
      placeholder="example.com"
      defaultActiveFirstOption
      options={toOptions(domains?.map((domain) => domain.name) ?? [])}
    />
  );
};

export const CustomSender = () => (
  <>
    <Field hidden name={["from", "type"]} initialValue="custom">
      <Input />
    </Field>
    <Row gutter={[8, 8]}>
      <Col span={14}>
        <Row>
          <Col span={10}>
            <Field
              name={["from", "username"]}
              wrapperCol={{ span: 24 }}
              rules={[
                {
                  type: "string",
                  pattern: /^[\w-.]+$/,
                  message: "Please use only alphanumeric characters, underscores, dots, or hyphens",
                },
              ]}
            >
              <Input style={{ textAlign: "right" }} placeholder="flight" addonAfter="@" />
            </Field>
          </Col>
          <Col span={14}>
            <Field name={["from", "domain"]}>
              <SenderDomainPicker />
            </Field>
          </Col>
        </Row>
      </Col>
      <Col span={10}>
        <Field name={["from", "name"]} wrapperCol={{ span: 24 }}>
          <Input placeholder="Name (optional)" />
        </Field>
      </Col>
    </Row>
  </>
);
