import type { ColumnsType } from "antd/lib/table/interface";
import { DescriptionCell } from "@/components/common/DescriptionCell";
import type { Action } from "@/api/flows/types";
import { Truncated } from "@/components/Activity/Truncated";

export const columns: ColumnsType<Action> = [
  {
    title: "Name",
    dataIndex: "label",
    render: (name) => <Truncated text={name} size={50} />,
  },
  {
    title: "Source Object",
    dataIndex: "sourceObject",
  },
  {
    title: "Description",
    dataIndex: "description",
    render: (description) => <DescriptionCell description={description} />,
  },
];
