import { create } from "zustand";
import type { AxiosResponse } from "axios";
import { openNotification } from "@/components/common/CommonNotification";
import { parseFileName } from "@/utils";
import fileDownload from "js-file-download";
import { useCallback } from "react";

type DownloadOptions = {
  message: string;
  noContentMessage: string;
  acceptedMessage: string;
};

type DownloadFetcher<Args extends any[]> = (...args: Args) => Promise<AxiosResponse<Blob>>;

type DownloadParams<Args extends any[]> = {
  key: string;
  fetcher: DownloadFetcher<Args>;
  options: DownloadOptions;
};

type DownloadsStore = {
  active: string[];
  download: (params: DownloadParams<[]>) => void;
};

export const useDownloadsStore = create<DownloadsStore>((set) => ({
  active: [],
  download: async ({ key, fetcher, options: { message, noContentMessage, acceptedMessage } }) => {
    set((state) => ({ active: [...state.active, key] }));
    try {
      openNotification({
        description: message,
      });
      const response = await fetcher();
      if (response.status === 204) {
        openNotification({
          type: "warning",
          description: noContentMessage,
        });
        return;
      }
      if (response.status === 202) {
        openNotification({
          type: "info",
          description: acceptedMessage,
        });
        return;
      }
      const fileName = parseFileName(response.headers["content-disposition"]);
      fileDownload(response.data, `${fileName}`, response.headers["content-type"]);
    } catch (error) {
      console.error(error);
    } finally {
      set((state) => ({ active: state.active.filter((x) => x !== key) }));
    }
  },
}));

export function useDownload<Args extends any[]>({ key, fetcher, options }: DownloadParams<Args>) {
  const downloading = useDownloadsStore((state) => state.active.includes(key));
  const download = useDownloadsStore((state) => state.download);

  const trigger = useCallback(
    (...args: Args) => {
      download({ key, fetcher: () => fetcher(...args), options });
    },
    [download, fetcher, key, options]
  );

  return {
    downloading,
    trigger,
  };
}
