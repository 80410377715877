import { Button, Form, Popconfirm, Select } from "antd";
import { useBoolean } from "ahooks";
import { omit } from "ramda";
import { useAuth } from "@/utils";
import { PermissionAction, PermissionSubject } from "@/api/users/types";
import Icon from "@ant-design/icons";
import { DropdownArrow } from "@/components/common/Icons";
import { useStorages } from "@/api/storages/hooks";
import { RestrictedField } from "@/components/common/RestrictedField";
import { StorageModal } from "@/components/Storage/StorageModal";
import "./StorageEditor.less";

type Props = {
  value?: string;
  onChange?: (value: string) => void;
};

export const StorageEditor = ({ value, onChange }: Props) => {
  const [form] = Form.useForm();

  const { data: storages, isLoading, error } = useStorages();

  const [isOpen, { setTrue: open, setFalse: close }] = useBoolean(false);

  const { ability } = useAuth();

  const canRead = ability.can(PermissionAction.Read, PermissionSubject.Storage);
  const canEdit = ability.can(PermissionAction.Update, PermissionSubject.Storage);
  const canCreate = ability.can(PermissionAction.Create, PermissionSubject.Storage);

  if (!canRead || error?.status === 403) {
    return <RestrictedField message="You need 'Read - Storage' permission to view this field" />;
  }

  const selectedStorage = storages.find((entry) => entry.id === value);

  const handleClose = () => {
    form.resetFields();
    close();
  };

  const handleEdit = () => {
    form.setFieldsValue(selectedStorage);
    open();
  };

  const handleDuplicate = () => {
    form.setFieldsValue(omit(["id"], selectedStorage));
    open();
  };

  const handleChange = (value: string) => {
    onChange?.(value ?? null);
  };

  return (
    <div className="storage-editor">
      <Select
        placeholder="Select Existing or Create New"
        loading={isLoading}
        disabled={isLoading}
        options={storages.map((entry) => ({ value: entry.id, label: entry.name }))}
        suffixIcon={<Icon component={DropdownArrow} />}
        value={value}
        onChange={handleChange}
        allowClear
      />
      {selectedStorage ? (
        <>
          <Popconfirm
            title="Editing existing storage may impact other reports that reference it"
            okText="Edit Anyway"
            onConfirm={handleEdit}
            overlayStyle={{ maxWidth: 300 }}
            disabled={!canEdit}
          >
            <Button size="small" disabled={!canEdit}>
              Edit
            </Button>
          </Popconfirm>
          <Button size="small" disabled={!canCreate} onClick={handleDuplicate}>
            Duplicate & Edit
          </Button>
        </>
      ) : (
        <Button disabled={!canCreate} type="primary" size="small" onClick={open}>
          Create
        </Button>
      )}
      <StorageModal open={isOpen} form={form} onClose={handleClose} onFinish={onChange} />
    </div>
  );
};
