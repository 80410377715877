import { useHistory } from "react-router-dom";
import { PageHeader, Table } from "antd";
import { columns } from "./columns";
import { useActions } from "@/api/flows/hooks";

export const ActionList = () => {
  const history = useHistory();

  const handleEdit = (key: string) => {
    history.push(`/actions/${key}`);
  };

  const { data, isLoading } = useActions();

  return (
    <>
      <PageHeader title={<h1>Actions</h1>} />
      <Table
        loading={isLoading}
        dataSource={data}
        rowKey={(entry) => entry.key}
        pagination={false}
        columns={columns}
        onRow={(entry) => {
          return {
            onClick: () => handleEdit(entry.key),
          };
        }}
      />
    </>
  );
};
