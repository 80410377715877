import type { EmailAlert } from "@/api/email-alerts/types";
import { Button, Form, PageHeader, Popconfirm, Tabs, Tooltip } from "antd";
import { Link, useHistory, useParams } from "react-router-dom";
import { useNavigationConfirm } from "@/utils/useNavigationConfirm";
import { useBoolean } from "ahooks";
import { FormActions } from "@/components/common/FormActions";
import { deleteEmailAlert } from "@/api/email-alerts";
import { stripUndefined, useAuth } from "@/utils";
import type { ComponentProps } from "react";
import { useCallback, useEffect, useMemo } from "react";
import { EmailAlertTab } from "@/utils/email-alert-tab";
import { tab } from "@/utils/tab";
import { EmailAlertSummaryForm } from "./EmailAlertSummaryForm";
import { useFormErrorsState } from "@/components/common/Form/hooks";
import * as R from "ramda";
import { EmailAlertEmailForm } from "./EmailAlertEmailForm";
import { FormErrorsContext } from "../../common/Form/context";
import { EmailAlertTypeSelector } from "./EmailAlertTypeSelector";
import { Summary } from "@/components/common/Summary/Summary";
import { HiddenField } from "@/components/common/HiddenField";
import { getOpenPlayEquivalent } from "@/utils/getOpenPlayEquivalent";
import { EmailAlertSpreadsheet } from "./EmailAlertSpreadsheet";
import type { FormValues } from "./utils";
import { parseEmailAlertFields, prepareEmailAlertDto } from "./utils";
import { useEmailAlertMutation } from "@/api/email-alerts/hooks";
import { PermissionAction, PermissionSubject } from "@/api/users/types";
import { ExtraActions } from "@/components/common/ExtraActions/ExtraActions";

type Props = {
  initialValues?: Partial<EmailAlert>;
  isEditingByDefault?: boolean;
};

const fieldsByTab = {
  [EmailAlertTab.Summary]: ["name", "sourceObject"],
  [EmailAlertTab.Email]: ["email", "variations"],
  [EmailAlertTab.Spreadsheet]: ["spreadsheet"],
};

export const EmailAlertForm = ({ initialValues = {}, isEditingByDefault }: Props) => {
  const [form] = Form.useForm<FormValues>();
  const history = useHistory();
  const { allowNavigation, preventNavigation } = useNavigationConfirm();

  const [editing, { setTrue: enableEditing, setFalse: disableEditing }] = useBoolean(isEditingByDefault);

  const initialFields = useMemo(() => parseEmailAlertFields(initialValues), [initialValues]);

  const { fieldsWithErrors, handleFieldsChange, resetErrors } = useFormErrorsState();
  const rootFieldsWithErrors = fieldsWithErrors.map((field) => field.name[0]);

  const isSummaryTabError = R.intersection(fieldsByTab.summary, rootFieldsWithErrors).length > 0;
  const isEmailTabError = R.intersection(fieldsByTab.email, rootFieldsWithErrors).length > 0;
  const isSpreadsheetTabError = R.intersection(fieldsByTab.spreadsheet, rootFieldsWithErrors).length > 0;

  const handleDelete = async () => {
    if (initialFields.id) {
      await deleteEmailAlert(initialFields.id);
      history.push(`/email-alerts`);
    }
  };

  const handleCancel = () => {
    allowNavigation();
    if (initialFields.id) {
      form.setFieldsValue(initialFields);
      resetErrors();
      disableEditing();
    } else {
      history.push(`/email-alerts`);
    }
  };

  const handleValueChange = () => {
    if (editing) {
      preventNavigation();
    }
  };

  const handleEdit = () => {
    enableEditing();
  };

  const { isMutating: saving, trigger: save } = useEmailAlertMutation(initialFields.id);

  const title = initialFields.name ?? "New Email Alert";

  const handleFinish = async (values: FormValues) => {
    try {
      const dto = prepareEmailAlertDto(values);
      const { id } = await save(stripUndefined(dto));
      allowNavigation();
      if (!initialFields.id) {
        history.push(`/email-alerts/${id}/${EmailAlertTab.Summary}`);
        return;
      }
      disableEditing();
    } catch (e) {
      console.error("unhandled error", e);
    }
  };

  const { activeTab = EmailAlertTab.Summary } = useParams<{ activeTab: EmailAlertTab }>();

  const onTabChanged = useCallback(
    (newTab: EmailAlertTab) => history.replace(`/email-alerts/${initialFields?.id || "new"}/${newTab}`),
    [initialFields, history]
  );

  const hasActions = initialValues.actions ? initialValues.actions.length > 0 : false;
  const isDeletable = !!initialFields.id && !editing && !hasActions;

  const sourceObjectName = Form.useWatch(["sourceObject", "name"], form);
  const sourceObjectLabel = Form.useWatch(["sourceObject", "label"], form);

  const openplayEquivalent = getOpenPlayEquivalent(sourceObjectName);

  const handleEmailAlertTypeSelect: ComponentProps<typeof EmailAlertTypeSelector>["onSelect"] = (values) => {
    form.setFieldsValue(values);
  };

  useEffect(() => {
    if (history.location.state?.editMode) {
      enableEditing();
    }
  }, [history.location.state?.editMode, enableEditing]);

  const { ability } = useAuth();

  const canEdit = ability.can(PermissionAction.Update, PermissionSubject.EmailAlert);
  const canDelete = ability.can(PermissionAction.Delete, PermissionSubject.EmailAlert);
  const canReadLogs = ability.can(PermissionAction.Read, PermissionSubject.ReportLog);

  const actions = [
    canReadLogs &&
      initialValues.id && {
        key: "logs",
        label: <Link to={`/email-alerts/${initialValues.id}/logs`}>Email Alert Logs</Link>,
      },
  ].filter(Boolean);

  return (
    <FormErrorsContext.Provider value={{ fieldsWithErrors }}>
      <PageHeader
        title={<h1 style={{ margin: 0 }}>{title}</h1>}
        extra={
          <>
            <div style={{ marginRight: 16 }}>
              <Summary items={[{ label: "Source Object", value: sourceObjectLabel ?? sourceObjectName }]} />
            </div>
            {actions.length > 0 && !editing && <ExtraActions items={actions} />}
            <FormActions
              isEditing={editing}
              onSave={form.submit}
              isSaving={saving}
              onEdit={handleEdit}
              onCancel={handleCancel}
              canEdit={canEdit}
            />
            {isDeletable && (
              <Popconfirm
                title="Are you sure to delete the email alert?"
                onConfirm={handleDelete}
                okText="Yes"
                cancelText="No"
                disabled={!canDelete}
              >
                <Button disabled={!canDelete}>Delete</Button>
              </Popconfirm>
            )}
            {hasActions && !editing && (
              <Tooltip title="This email alert is currently in use by one or more flows and cannot be deleted">
                <Button disabled>Delete</Button>
              </Tooltip>
            )}
          </>
        }
      />
      <Form<FormValues>
        form={form}
        initialValues={initialFields}
        onFinish={handleFinish}
        validateMessages={{ required: "Field is mandatory" }}
        labelAlign="left"
        onFieldsChange={handleFieldsChange}
        onValuesChange={handleValueChange}
      >
        <HiddenField name="id" />
        <HiddenField name={["sourceObject", "name"]} />
        <HiddenField name={["sourceObject", "label"]} />
        {sourceObjectName ? (
          <Tabs
            activeKey={activeTab}
            onChange={onTabChanged}
            items={[
              tab({
                key: EmailAlertTab.Summary,
                label: "Summary",
                hasErrors: isSummaryTabError,
                content: <EmailAlertSummaryForm readOnly={!editing} />,
              }),
              tab({
                key: EmailAlertTab.Email,
                label: "Email",
                hasErrors: isEmailTabError,
                content: <EmailAlertEmailForm readOnly={!editing} />,
              }),
              openplayEquivalent &&
                tab({
                  key: EmailAlertTab.Spreadsheet,
                  label: "Spreadsheet",
                  hasErrors: isSpreadsheetTabError,
                  content: <EmailAlertSpreadsheet readOnly={!editing} />,
                }),
            ].filter(Boolean)}
          />
        ) : (
          <EmailAlertTypeSelector onSelect={handleEmailAlertTypeSelect} />
        )}
      </Form>
    </FormErrorsContext.Provider>
  );
};
