import { useParams } from "react-router-dom";
import { ActionForm } from "./ActionForm";
import { Spinner } from "../../../common/Spinner/Spinner";
import { useAction } from "@/api/flows/hooks";

export const ActionPage = () => {
  const { key } = useParams<{ key: string }>();

  const { data: action, isLoading } = useAction(key);

  if (isLoading) {
    return <Spinner />;
  }

  return <ActionForm initialValues={action} />;
};
