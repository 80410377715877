import { Field } from "@/components/common/Form/Field";
import { TextFieldWithVariables } from "@/components/common/RichTextEditor/TextFieldWithVariables";
import { useEmailMessageContext } from "@/components/common/EmailMessageForm/context";

export type SubjectProps = {
  label?: string;
};

export const Subject = ({ label }: SubjectProps) => {
  const { variables } = useEmailMessageContext();
  return (
    <Field
      name="subject"
      label={label}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      normalize={(value) => value?.trim()}
      validateFirst
      extra="To insert a variable, begin typing '{' (curly brace)"
    >
      <TextFieldWithVariables variables={variables} />
    </Field>
  );
};
