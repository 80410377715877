import type { RecurrencyFields } from "@/utils/recurrency";
import { parseRecurrencyFields, prepareRecurrencyDto } from "@/utils/recurrency";
import * as R from "ramda";
import type { CreateReportDto, Report, UpdateReportDto, Variation } from "@/api/reports/types";
import { ReportStatus } from "@/api/reports/types";
import type { Spreadsheet } from "@/api/spreadsheets/types";

export type FormValues = Omit<CreateReportDto | UpdateReportDto, "recurrency"> & {
  recurrency: RecurrencyFields;
  hasSpreadsheet: boolean;
  spreadsheet: Spreadsheet;
};

export const parseVariationFields = (fields: Variation) => ({
  ...fields,
  query: {
    where: fields.query.where,
  },
});

export const parseReportFields = (fields: Partial<Report>) => ({
  status: ReportStatus.Draft,
  ...fields,
  recurrency: parseRecurrencyFields(fields.recurrency),
  queries: fields.queries,
  variations: (fields.variations ?? []).map(parseVariationFields),
  hasSpreadsheet: !!fields.spreadsheet,
});

export const prepareVariationDto = (fields: Variation, allowedVariables: string[] = []) => ({
  ...fields,
  query: {
    where: fields.query.where,
  },
  variables: R.pick(allowedVariables, fields.variables),
});

export const prepareReportDto = ({ hasSpreadsheet, ...values }: FormValues) => ({
  ...values,
  recurrency: prepareRecurrencyDto(values.recurrency),
  queries: values.queries,
  variations: (values.variations ?? []).map((variation) => prepareVariationDto(variation, values.variables)),
  spreadsheet: hasSpreadsheet
    ? {
        ...values.spreadsheet,
        storage: values.spreadsheet.storage.id ? values.spreadsheet.storage : { id: null },
      }
    : null,
});
