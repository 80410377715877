import useSWR, { mutate } from "swr";
import useSWRMutation from "swr/mutation";
import { fetchStorages, saveStorage } from "@/api/storages/index";
import type { CreateStorageDto, UpdateStorageDto } from "@/api/storages/types";

const key = "storages";

export const useStorages = () => useSWR([key], fetchStorages, { fallbackData: [] });

export const useStorageMutation = (id: string) =>
  useSWRMutation(
    id ? [key, id] : [key],
    (_, { arg: dto }: { arg: CreateStorageDto | UpdateStorageDto }) => saveStorage(dto),
    { onSuccess: () => mutate([key]) }
  );
