import { FieldList, FieldListItem } from "@/components/common/Form/FieldList";
import { FullWidthButton } from "@/components/common/FullWidthButton/FullWidthButton";
import { Plus } from "@/components/common/Icons";
import { FlowAction } from "./FlowAction";
import { SortableList, SortableListItem } from "@/components/common/SortableList";
import "./FlowActionList.less";
import type { ComponentProps } from "react";
import { Form } from "antd";
import type { FlowTriggerType } from "@/api/flows/types";

type Props = {
  triggerType: FlowTriggerType;
  sourceObject: string;
} & Pick<ComponentProps<typeof FieldList>, "rules">;

export const FlowActionList = ({ triggerType, sourceObject, rules }: Props) => {
  return (
    <FieldList name="actions" rules={rules}>
      {(fields, { add, remove, move }, { errors }) => (
        <div>
          <div className="flow-action-list">
            <SortableList onMove={move}>
              {fields.map((field) => (
                <SortableListItem key={field.key} index={field.name} onRemove={() => remove(field.name)}>
                  <FieldListItem name={field.name}>
                    <FlowAction triggerType={triggerType} sourceObject={sourceObject} index={field.name} />
                  </FieldListItem>
                </SortableListItem>
              ))}
            </SortableList>
            <FullWidthButton label="Add Action" icon={Plus} onClick={() => add()} small />
          </div>
          <Form.ErrorList errors={errors} />
        </div>
      )}
    </FieldList>
  );
};
