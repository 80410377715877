import "./Login.less";
import { Button, Form, Spin } from "antd";
import { useAuth } from "@/utils";
import { Redirect, useLocation } from "react-router-dom";
import { Logo, LogoColor, LogoSize } from "../Layout/Logo";

function Login() {
  const location = useLocation();
  const { isAuthenticated, isLoading } = useAuth();
  const [form] = Form.useForm();

  const params = new URLSearchParams(location.search);

  const handleSamlLogin = () => {
    window.location.href = "/api/auth/sign-in";
  };

  if (isAuthenticated) {
    return <Redirect to={location.state?.from ?? "/"} />;
  }

  return (
    <div className="login-form">
      <Spin spinning={isLoading}>
        <Form name="normal_login" className="login-form__box" form={form}>
          <div className="login-form__logo">
            <Logo size={LogoSize.Large} color={LogoColor.Black} width={200} height={60} />
          </div>
          <Button type="primary" className="login-form__button" onClick={handleSamlLogin}>
            Login with SSO
          </Button>
          {params.get("reason") === "deactivated" && (
            <p className="login-form__error">The user was deactivated</p>
          )}
        </Form>
      </Spin>
    </div>
  );
}

export default Login;
