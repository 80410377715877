import { ExtraActions } from "@/components/common/ExtraActions/ExtraActions";
import { ExportReportButton } from "@/components/Reports/ExportReportButton";
import type { MenuProps } from "antd";
import { Space } from "antd";
import { downloadSpreadsheet } from "@/api/reports";
import type { Report } from "@/api/reports/types";
import { DownloadOutlined, LoadingOutlined } from "@ant-design/icons";
import { useAuth } from "@/utils";
import { PermissionAction, PermissionSubject } from "@/api/users/types";
import { Link } from "react-router-dom";
import { useDownload } from "@/stores/downloads";

type Props = {
  report: Report;
};

const downloadOptions = {
  message: "The spreadsheet is being generated. Download will start automatically",
  noContentMessage: "The are no records to include into the file",
  acceptedMessage:
    "The spreadsheet generation is taking longer than expected. You will receive an email once the file is ready",
};

export const ReportExtraActions = ({ report }: Props) => {
  const { ability } = useAuth();

  const { trigger, downloading } = useDownload({
    key: `report-${report.id}`,
    options: downloadOptions,
    fetcher: downloadSpreadsheet,
  });

  const actions: MenuProps["items"] = [
    {
      key: "export",
      label: <ExportReportButton id={report.id} />,
    },
    report.variations.length === 0 && {
      key: "download",
      label: (
        <Space role="button" onClick={() => trigger(report.id)}>
          {downloading ? <LoadingOutlined /> : <DownloadOutlined />}
          <span>Download .xlsx</span>
        </Space>
      ),
    },
    report.variations.length > 0 && {
      key: "download",
      label: (
        <Space>
          {downloading ? <LoadingOutlined /> : <DownloadOutlined />}
          <span>Download .xlsx</span>
        </Space>
      ),
      children: report.variations.map((variation, idx) => {
        const label = variation.name?.trim() || `Variation #${idx + 1}`;
        return {
          key: idx,
          label,
          onClick: () => trigger(report.id, idx),
        };
      }),
    },
    ability.can(PermissionAction.Read, PermissionSubject.ReportLog) && {
      key: "logs",
      label: <Link to={`/reports/${report.id}/logs`}>Report Logs</Link>,
    },
  ].filter(Boolean);

  return <ExtraActions key="actions" items={actions} />;
};
