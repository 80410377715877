import { TableWithPaginationAndSorting } from "@/components/common";
import FilterableContent from "@/components/Layout/FilterableContent";
import { Order, prepareParams } from "@/utils";
import { useGridParams } from "@/utils/useGridParams";
import { useReportLogs } from "@/api/report-logs/hooks";
import { ReportLogColumn } from "./columns";
import type { Filter } from "@/components/common/FilterInput/FilterInput";
import { FilterType } from "@/components/common/FilterInput/FilterInput";
import { ReportLogParentType, ReportLogStatus } from "@/api/report-logs/types";
import { ReportPicker } from "@/components/Reports/ReportPicker";
import { ReportLogDetails } from "./ReportLogDetails";
import { useMemo } from "react";
import { EmailAlertPicker } from "@/components/EmailAlerts/EmailAlertPicker";

type Props =
  | {
      parentType: ReportLogParentType.Report;
      reportId?: string;
    }
  | {
      parentType: ReportLogParentType.EmailAlert;
      emailAlertId?: string;
    };

export const ReportLogTable = (props: Props) => {
  const { filters, pagination, sort } = useGridParams();

  const getFilters = () => {
    const adjustedFilters: any = { ...filters, parentType: props.parentType };
    if (props.parentType === ReportLogParentType.Report && props.reportId) {
      adjustedFilters.reportId = props.reportId;
    }
    if (props.parentType === ReportLogParentType.EmailAlert && props.emailAlertId) {
      adjustedFilters.emailAlertId = props.emailAlertId;
    }
    return adjustedFilters;
  };

  const { data, isLoading } = useReportLogs(prepareParams(getFilters(), pagination, sort));

  const columns = useMemo(
    () =>
      [
        ReportLogColumn.Status,
        ReportLogColumn.Date,
        props.parentType === ReportLogParentType.Report && !props.reportId && ReportLogColumn.Report,
        props.parentType === ReportLogParentType.Report && ReportLogColumn.Variation,
        props.parentType === ReportLogParentType.EmailAlert &&
          !props.emailAlertId &&
          ReportLogColumn.EmailAlert,
        ReportLogColumn.Actions,
      ].filter(Boolean),
    [props]
  );

  const filterConfig: Filter[] = useMemo(
    () =>
      [
        props.parentType === ReportLogParentType.Report &&
          !props.reportId && {
            type: FilterType.CustomInput,
            name: "reportId",
            label: "Report",
            input: <ReportPicker />,
          },
        props.parentType === ReportLogParentType.EmailAlert &&
          !props.emailAlertId && {
            type: FilterType.CustomInput,
            name: "emailAlertId",
            label: "Email Alert",
            input: <EmailAlertPicker placeholder="Select Email Alert" />,
          },
        {
          type: FilterType.Radio,
          name: "status",
          label: "Status",
          options: [
            { value: ReportLogStatus.Sent, label: "Sent" },
            { value: ReportLogStatus.Failed, label: "Failed" },
          ],
        },
        { name: "createdAt", label: "Date", type: FilterType.DateRange },
      ].filter(Boolean),
    [props]
  );

  return (
    <FilterableContent formConfig={filterConfig} filterName="report-log-filter" isFiltersConfigurable={false}>
      <TableWithPaginationAndSorting
        rowKey="id"
        columns={columns}
        data={data}
        loading={isLoading}
        scroll={{ x: true }}
        defaultOrder={Order.DESC}
        defaultSortColumn="createdAt"
        expandable={{
          rowExpandable: (log) =>
            !!log.email?.from || !!log.email?.subject || log.status === ReportLogStatus.Failed,
          expandedRowRender: (log) => <ReportLogDetails log={log} />,
        }}
      />
    </FilterableContent>
  );
};
