import { Form, Select } from "antd";
import { Field } from "@/components/common/Form/Field";
import { useFieldPath } from "@/components/common/Form/hooks";
import "./FlowAction.less";
import { useActions } from "@/api/flows/hooks";
import Icon from "@ant-design/icons";
import { DropdownArrow } from "@/components/common/Icons";
import type { FlowTriggerType } from "@/api/flows/types";

type Props = {
  index: number;
  sourceObject: string;
  triggerType: FlowTriggerType;
};

export const FlowAction = ({ triggerType, sourceObject, index }: Props) => {
  const { getAbsolutePath } = useFieldPath();

  const { isLoading, data: entries } = useActions({ triggerType, sourceObject, includeParams: true });

  const selectedActionType = Form.useWatch(getAbsolutePath("type"));

  const selectedAction = entries.find((entry) => entry.key === selectedActionType);

  return (
    <div className="flow-action">
      <div className="flow-action__index">{`#${index + 1}`}</div>
      <Field name="type" rules={[{ required: true, message: "Please, select an action to run" }]}>
        <Select
          disabled={isLoading}
          options={entries.map((entry) => ({ value: entry.key, label: entry.label }))}
          placeholder="Select an action"
          suffixIcon={<Icon component={DropdownArrow} />}
        />
      </Field>
      {selectedAction?.params.map((param) => (
        <Field
          key={param.name}
          name={["params", param.name]}
          rules={[{ required: true, message: `Please, select ${param.label.toLowerCase()}` }]}
        >
          <Select
            options={param.options
              .filter((entry) =>
                entry.sourceObject && sourceObject ? entry.sourceObject === sourceObject : true
              )
              .map((entry) => ({ value: entry.value, label: entry.label }))}
            placeholder={`Select ${param.label.toLowerCase()}`}
            suffixIcon={<Icon component={DropdownArrow} />}
          />
        </Field>
      ))}
    </div>
  );
};
