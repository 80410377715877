import type { FormProps } from "antd";
import { Alert, Col, Form, Input, InputNumber, Modal, Row, Select } from "antd";
import { useState } from "react";
import { HiddenField } from "@/components/common/HiddenField";
import { FormProvider } from "@/components/common/Form/FormProvider";
import { useStorageMutation } from "@/api/storages/hooks";
import { Field } from "@/components/common/Form/Field";
import { StorageType } from "@/api/storages/types";
import Icon from "@ant-design/icons";
import { DropdownArrow } from "@/components/common/Icons";
import { testStorage } from "@/api/storages";

type Props = {
  onClose?: () => void;
  onFinish?: (id: string) => void;
  open?: boolean;
} & Pick<FormProps, "form">;

const fieldLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

export const StorageModal = ({ onClose, onFinish, open, form }: Props) => {
  const [submitting, setSubmitting] = useState(false);

  const id = Form.useWatch<string>("id", form);
  const type = Form.useWatch<StorageType>("type", form);

  const privateKey = Form.useWatch<string>("privateKey", form);
  const password = Form.useWatch<string>("password", form);

  const { trigger: save, isMutating: saving } = useStorageMutation(id);
  const [error, setError] = useState(null);

  const handleFinish = async (values) => {
    try {
      setError(null);
      const { success, reason } = await testStorage(values);
      if (!success) {
        setError(reason ? `Connection failed: ${reason}` : "Failed to connect to the storage");
        return;
      }
      const { id } = await save(values);
      onFinish(id);
      onClose();
      form.resetFields();
    } catch (e) {
      console.error(e);
    } finally {
      setSubmitting(false);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      title="Edit Storage"
      okButtonProps={{ size: "small", loading: saving || submitting }}
      cancelButtonProps={{ size: "small" }}
      okText="Save"
      width={1000}
      open={open}
      onCancel={handleCancel}
      onOk={() => {
        setSubmitting(true);
        form.submit();
      }}
    >
      <FormProvider>
        <Form form={form} onFinish={handleFinish} onFinishFailed={() => setSubmitting(false)}>
          <HiddenField name="id" />
          <Row gutter={[16, 16]}>
            {error && (
              <Col span={24}>
                <Alert type="error" message={error} />
              </Col>
            )}
            <Col span={12}>
              <Field
                label="Name"
                name="name"
                rules={[{ required: true, message: "Please, specify storage name" }]}
                {...fieldLayout}
              >
                <Input placeholder="Storage Name" />
              </Field>
            </Col>
            <Col span={12}>
              <Field
                label="Type"
                name="type"
                rules={[{ required: true, message: "Please, select storage type" }]}
                {...fieldLayout}
              >
                <Select
                  placeholder="Select Storage Type"
                  disabled={!!id}
                  options={[
                    { value: StorageType.S3, label: "Amazon S3" },
                    { value: StorageType.FTP, label: "FTP" },
                    { value: StorageType.sFTP, label: "sFTP" },
                  ]}
                  suffixIcon={<Icon component={DropdownArrow} />}
                />
              </Field>
            </Col>
            {type === StorageType.S3 && (
              <>
                <Col span={12}>
                  <Field
                    label="Access Key ID"
                    name={["credentials", "accessKeyId"]}
                    rules={[{ required: true, message: "Please, specify access key ID" }]}
                    {...fieldLayout}
                  >
                    <Input placeholder="Access Key ID" />
                  </Field>
                </Col>
                <Col span={12}>
                  <Field
                    label="Secret Access Key"
                    name={["credentials", "secretAccessKey"]}
                    rules={[{ required: true, message: "Please, specify secret access key" }]}
                    {...fieldLayout}
                  >
                    <Input.Password placeholder="Secret Access Key" />
                  </Field>
                </Col>
                <Col span={12}>
                  <Field
                    label="Path"
                    name={["credentials", "path"]}
                    rules={[{ required: true, message: "Please, specify path" }]}
                    {...fieldLayout}
                  >
                    <Input placeholder="Path" />
                  </Field>
                </Col>
              </>
            )}
            {(type === StorageType.FTP || type === StorageType.sFTP) && (
              <>
                <Col span={12}>
                  <Field
                    label="Host"
                    name={["credentials", "host"]}
                    rules={[
                      {
                        required: true,
                        message: "Please, specify host",
                        transform: (value) => value?.trim(),
                      },
                    ]}
                    {...fieldLayout}
                  >
                    <Input placeholder="Host" />
                  </Field>
                </Col>
                <Col span={12}>
                  <Field
                    label="Port"
                    name={["credentials", "port"]}
                    rules={[{ required: true, message: "Please, specify port" }]}
                    {...fieldLayout}
                  >
                    <InputNumber controls={false} min={0} placeholder="Port" />
                  </Field>
                </Col>
                <Col span={12}>
                  <Field
                    label="Username"
                    name={["credentials", "user"]}
                    rules={[
                      {
                        required: true,
                        message: "Please, specify username",
                        transform: (value) => value?.trim(),
                      },
                    ]}
                    {...fieldLayout}
                  >
                    <Input placeholder="Username" />
                  </Field>
                </Col>
                <Col span={12}>
                  <Field
                    label="Password"
                    name={["credentials", "password"]}
                    normalize={(value) => value?.trim()}
                    {...fieldLayout}
                  >
                    <Input.Password placeholder="Password" disabled={!!privateKey} />
                  </Field>
                </Col>
                <Col span={12}>
                  <Field label="Path" name={["credentials", "path"]} {...fieldLayout}>
                    <Input placeholder="Path" />
                  </Field>
                </Col>
              </>
            )}
            {type === StorageType.sFTP && (
              <>
                <Col span={24}>
                  <Field
                    label="Private Key"
                    name={["credentials", "privateKey"]}
                    normalize={(value) => value?.trim()}
                    {...fieldLayout}
                  >
                    <Input.TextArea placeholder="Private Key" disabled={!!password} />
                  </Field>
                </Col>
                <Col span={12}>
                  <Field
                    label="Passphrase"
                    name={["credentials", "passphrase"]}
                    normalize={(value) => value?.trim()}
                    {...fieldLayout}
                  >
                    <Input.Password placeholder="Passphrase" disabled={!!password} />
                  </Field>
                </Col>
              </>
            )}
          </Row>
        </Form>
      </FormProvider>
    </Modal>
  );
};
