import type { FormItemProps } from "antd";
import { Input } from "antd";
import React from "react";
import { Field } from "@/components/common/Form/Field";
import { TenantPicker } from "@/components/OpenplayQueries/TenantPicker";
import type { QueryParam } from "@/api/openplay-queries/types";
import { defaultValuesByType } from "@/components/OpenplayQueries/QueryParameterField";
import { QueryParameterValueInput } from "@/components/OpenplayQueries/QueryParameterValueInput";

export const SourcedParameterPlaceholder = () => (
  <Input disabled value={null} placeholder="Sourced from Salesforce query" />
);

export const TenantField = ({ name }: Pick<FormItemProps, "name">) => (
  <Field
    label="Tenant"
    name={name}
    rules={[{ required: true, message: "Please, select tenant" }]}
    wrapperCol={{ span: 24 }}
    labelCol={{ span: 24 }}
  >
    <TenantPicker />
  </Field>
);

export const ParameterField = ({
  name,
  type,
  isSourced,
  label,
}: Pick<FormItemProps, "name" | "label"> &
  Pick<QueryParam, "type"> & {
    isSourced?: boolean;
  }) => (
  <Field
    label={label}
    name={name}
    initialValue={defaultValuesByType[type]}
    wrapperCol={{ span: 24 }}
    labelCol={{ span: 24 }}
    rules={[
      {
        required: !isSourced,
        message: "Please, provide value for the parameter",
      },
    ]}
  >
    {isSourced ? (
      <SourcedParameterPlaceholder />
    ) : (
      <QueryParameterValueInput type={type} placeholder="Parameter Value" />
    )}
  </Field>
);
