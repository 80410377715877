import { Col, Form, Row, Switch } from "antd";
import { Box } from "@/components/common/Box/Box";
import { Field } from "@/components/common/Form/Field";
import { AddSenderToBCC, CustomSender, Recipients, Subject } from "@/components/common/EmailMessageForm";
import { FieldGroup } from "@/components/common/Form/FieldGroup";
import { useReportVariables } from "@/components/Reports/variables";
import { ContentBuilder } from "@/components/common/ContentBuilder/ContentBuilder";
import { EmailMessageContextProvider } from "@/components/common/EmailMessageForm/context";

import { getFieldsForQueryBuilder } from "@/utils";

type Props = {
  readOnly: boolean;
};

export const ReportEmailForm = ({ readOnly }: Props) => {
  const variables = useReportVariables();
  const fieldsForConditions = getFieldsForQueryBuilder(variables);

  return (
    <EmailMessageContextProvider
      variables={variables}
      fieldsForConditions={fieldsForConditions}
      parentType="report"
    >
      <FieldGroup prefix="email">
        <Box readOnly={readOnly}>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Field
                label="Enabled"
                name="enabled"
                valuePropName="checked"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValue={true}
              >
                <Switch />
              </Field>
            </Col>
            <Col span={24}>
              <Field label="From" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                <CustomSender />
              </Field>
            </Col>
            <Col span={24}>
              <Subject label="Subject" />
            </Col>
            <Col span={24}>
              <Recipients label="To" name="to" />
            </Col>
            <Col span={24}>
              <Recipients label="CC" name="cc" />
            </Col>
            <Col span={24}>
              <AddSenderToBCC />
            </Col>
            <Col span={24}>
              <Form.Item label="Body" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                <ContentBuilder maxNesting={2} name="body" />
              </Form.Item>
            </Col>
          </Row>
        </Box>
      </FieldGroup>
    </EmailMessageContextProvider>
  );
};
