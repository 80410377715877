import { useBoolean } from "ahooks";
import { type ComponentProps, useState } from "react";
import { ReadOnlyCondition } from "./ReadOnlyCondition/ReadOnlyCondition";
import { IconButton } from "@/components/common/IconButton/IconButton";
import { Filters } from "@/components/common/Icons";
import { Form, Modal } from "antd";
import { QueryBuilder } from "@/components/common/QueryBuilder/QueryBuilder";
import type { Config, JsonLogicTree } from "@react-awesome-query-builder/core";

type Props = {
  value?: JsonLogicTree;
  onChange?: (value: JsonLogicTree) => void;
  onBlur?: ComponentProps<"div">["onBlur"];
  config: Config;
};
export const ModalQueryBuilder = ({ value, config, onChange, onBlur }: Props) => {
  const [isOpen, { setTrue: open, setFalse: close }] = useBoolean(false);
  const [innerValue, setInnerValue] = useState(null);

  const handleOpen = () => {
    setInnerValue(value);
    open();
  };

  const handleOk = () => {
    onChange?.(innerValue);
    close();
  };

  return (
    <>
      <div className="flex-row" onBlur={onBlur}>
        <div className="flex-grow">
          <ReadOnlyCondition value={value} config={config} />
        </div>
        <IconButton icon={Filters} onClick={handleOpen} />
      </div>
      <Modal open={isOpen} title="Edit Condittion" width={1200} onCancel={close} onOk={handleOk}>
        <Form.Item>
          <QueryBuilder value={innerValue} onChange={setInnerValue} config={config} />
        </Form.Item>
      </Modal>
    </>
  );
};