import { useSalesforceQueryBuilderConfig } from "./config";
import type { ComponentProps } from "react";
import { Spinner } from "@/components/common/Spinner/Spinner";
import { Alert } from "antd";
import { RestrictedField } from "@/components/common/RestrictedField";
import { ModalQueryBuilder } from "@/components/common/QueryBuilder";

interface SalesforceQueryBuilderProps extends Omit<ComponentProps<typeof ModalQueryBuilder>, "config"> {
  from: string;
}

export const SalesforceQueryBuilder = ({ value, onChange, from, onBlur }: SalesforceQueryBuilderProps) => {
  const { config, loading, error } = useSalesforceQueryBuilderConfig(from);

  if (error?.status === 403) {
    return <RestrictedField message="You need 'Read - Salesforce Object' permission to view this field" />;
  }

  if (loading) {
    return <Spinner height={70} />;
  }

  return config ? (
    <ModalQueryBuilder value={value} onChange={onChange} onBlur={onBlur} config={config} />
  ) : (
    <Alert message={`Please, import '${from}' object from Salesforce to use this functionality`} />
  );
};
