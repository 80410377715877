import type { FormInstance } from "antd";
import { Alert, Button, Col, Divider, Form, Input, Row, Space, Spin } from "antd";
import { Field } from "@/components/common/Form/Field";
import { isUniqueName } from "@/utils/validation";
import { checkOpenplayQuery, checkOpenplayQueryExistence } from "@/api/openplay-queries";
import { capitalize } from "@/utils";
import { OpenplaySQLEditor } from "./Details/OpenplaySQLEditor";
import { useRequest } from "ahooks";
import { useEffect } from "react";
import { isNil, uniq } from "ramda";
import { QueryParameterField } from "./QueryParameterField";
import { TenantPicker } from "./TenantPicker";
import type { CheckQueryResult, QueryWithParameters } from "@/api/openplay-queries/types";
import { QueryParamType } from "@/api/openplay-queries/types";
import { openNotification } from "@/components/common/CommonNotification";

const { TextArea } = Input;

type Props = {
  readOnly?: boolean;
};

const extractParameters = (sql: string): { tenant: boolean; params: string[] } => {
  if (!sql) {
    return {
      tenant: false,
      params: [],
    };
  }
  const tenant = sql.includes(" tenant.");
  const params = uniq([...sql.matchAll(/(?<=[\s(]):(\w+)\b/g)].map((match) => match[1]));
  return { tenant, params };
};

const isValidDefaultValue = (value?: string | number | boolean | string[]) => {
  if (typeof value === "string") {
    return !!value.trim();
  }
  if (Array.isArray(value)) {
    return value.length > 0;
  }
  return !isNil(value);
};

const prepareQueryWithParameters = async (form: FormInstance) => {
  const sql = form.getFieldValue("sql");
  if (!sql?.trim()) {
    throw new Error("The query is not provided");
  }
  const { tenant, params } = extractParameters(sql);
  const opts: QueryWithParameters = { sql };
  if (tenant) {
    const defaultTenant = await form.getFieldValue("defaultTenant");
    if (!defaultTenant?.trim()) {
      throw new Error("Default tenant is not provided");
    }
    opts.tenant = defaultTenant;
  }
  if (params.length > 0) {
    opts.parameters = {};
    for (const param of params) {
      const type = form.getFieldValue(["parameters", param, "type"]) as QueryParamType;
      let defaultValue = form.getFieldValue(["parameters", param, "defaultValue"]);
      if (!type) {
        throw new Error("Type is not provided for some parameters");
      }
      if (type === QueryParamType.Boolean && !defaultValue) {
        defaultValue = false;
      }
      if (!isValidDefaultValue(defaultValue)) {
        throw new Error("Default value is not provided for some parameters");
      }
      opts.parameters[param] = { type, defaultValue };
    }
  }
  return opts;
};

const INVALID_PARAMETERS_ERROR = "Please, make sure default values for parameters are provided";

const getQueryStatusMessage = (status: CheckQueryResult) =>
  status.success === true
    ? "Query is correct."
    : `Query has an error: ${capitalize(status.reason)}. Please review your SQL syntax.`;

export const OpenplayQueryFields = ({ readOnly }: Props) => {
  const { data: status, loading: refreshing, run: refresh, reset } = useRequest(checkOpenplayQuery, {
    manual: true,
    onSuccess: (result) => {
      openNotification({
        type: result.success ? "success" : "error",
        message: getQueryStatusMessage(result),
      });
    },
  });

  const form = Form.useFormInstance();

  const sql = Form.useWatch<string>("sql");

  const canCheckQuery = !!sql?.trim();

  const { tenant, params } = extractParameters(sql);

  const handleCheckQuery = async () => {
    try {
      form.setFields([{ name: "sql", errors: [] }]);
      const params = await prepareQueryWithParameters(form);
      return refresh(params);
    } catch (_) {
      form.setFields([{ name: "sql", errors: [INVALID_PARAMETERS_ERROR] }]);
    }
  };

  useEffect(() => {
    reset();
  }, [reset, sql]);

  const showParamsSection = tenant || params.length > 0;

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Field
            name="name"
            label="Name"
            validateTrigger={["onChange", "onBlur"]}
            rules={[
              { required: true, message: "Please, enter a name for the query" },
              isUniqueName(
                checkOpenplayQueryExistence,
                "A query with entered name already exists in the system"
              ),
            ]}
          >
            <Input placeholder="Name" />
          </Field>
        </Col>
        <Col span={12}>
          <Field name="description" label="Description">
            <TextArea placeholder="Description" rows={2} />
          </Field>
        </Col>
        <Col span={24}>
          <Form.Item label="SQL" wrapperCol={{ span: 24 }} labelCol={{ span: 24 }}>
            <Space direction="vertical" style={{ width: "100%" }}>
              {status && (
                <Spin spinning={refreshing}>
                  <Alert
                    type={status.success ? "success" : "error"}
                    message={getQueryStatusMessage(status)}
                  />
                </Spin>
              )}
              <Field
                name="sql"
                validateFirst
                rules={[
                  {
                    required: true,
                    message: "Please, provide an SQL query",
                    transform: (value) => value?.trim(),
                  },
                  {
                    validator: async () => {
                      try {
                        const params = await prepareQueryWithParameters(form);
                        const status = await checkOpenplayQuery(params);
                        if (status.success === true) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(status.reason);
                        }
                      } catch (e) {
                        return Promise.reject(INVALID_PARAMETERS_ERROR);
                      }
                    },
                    validateTrigger: ["onSubmit"],
                  },
                ]}
                validateTrigger={["onChange", "onSubmit"]}
              >
                <OpenplaySQLEditor placeholder="SELECT id FROM ..." readOnly={readOnly} />
              </Field>
              <Button size="small" loading={refreshing} onClick={handleCheckQuery} disabled={!canCheckQuery}>
                Check Query
              </Button>
            </Space>
          </Form.Item>
        </Col>
      </Row>
      {showParamsSection && (
        <>
          <Divider>Parameters</Divider>
          <Row gutter={[16, 16]}>
            {tenant && (
              <>
                <Col span={12}>
                  <Field
                    label="Default Tenant"
                    name="defaultTenant"
                    preserve={false}
                    rules={[{ required: true, message: "Please, select default tenant" }]}
                  >
                    <TenantPicker />
                  </Field>
                </Col>
                <Col span={12} />
              </>
            )}
            {params.map((param) => (
              <Col key={param} span={12}>
                <QueryParameterField name={param} />
              </Col>
            ))}
          </Row>
        </>
      )}
    </>
  );
};
