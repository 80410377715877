import { Layout, Menu } from "antd";
import Icon from "@ant-design/icons";
import {
  Cloud,
  Dashboard,
  ExportSchedule,
  Flow,
  Gears,
  Link,
  Mail,
  Movement,
  OpenPlay,
  Salesforce,
  Users,
  Wrench,
} from "../../common/Icons";
import { isNotEmpty, useAuth } from "@/utils";
import * as R from "ramda";
import { useLocation } from "react-router-dom";
import { LinkWithConfirmation } from "@/utils/useNavigationConfirm";
import "./Sidebar.less";
import { Logo, LogoColor, LogoSize } from "../Logo";
import { defaultStatusesQuery } from "@/components/Activity/utils";
import type { ReactElement } from "react";
import { PermissionAction, PermissionSubject } from "@/api/users/types";

const { Sider } = Layout;

type MenuItem = {
  key: string;
  route?: string | { pathname: string; search: string };
  title: string;
  icon: ReactElement;
  permissions: [PermissionAction, PermissionSubject][];
  children?: Omit<MenuItem, "icon">[];
};

const menu: MenuItem[] = [
  {
    key: "releases",
    route: "/releases",
    title: "Releases",
    permissions: [[PermissionAction.Read, PermissionSubject.Release]],
    icon: Dashboard,
  },
  {
    key: "partners",
    route: "/partners",
    title: "Partners",
    permissions: [[PermissionAction.Read, PermissionSubject.Partner]],
    icon: Cloud,
  },
  {
    key: "export-schedules",
    route: "/export-schedules",
    title: "Export Schedules",
    permissions: [[PermissionAction.Read, PermissionSubject.ExportSchedule]],
    icon: ExportSchedule,
  },
  {
    key: "_users",
    title: "Users",
    icon: Users,
    permissions: [],
    children: [
      {
        key: "users",
        route: "/users",
        title: "All Users",
        permissions: [[PermissionAction.Read, PermissionSubject.User]],
      },
      {
        key: "permission-sets",
        route: "/permission-sets",
        title: "Permission Sets",
        permissions: [[PermissionAction.Read, PermissionSubject.PermissionSet]],
      },
    ],
  },
  {
    key: "templates",
    route: "/templates",
    title: "Templates",
    permissions: [[PermissionAction.Read, PermissionSubject.Template]],
    icon: Wrench,
  },
  {
    key: "activity",
    route: {
      pathname: "/activity/release",
      search: defaultStatusesQuery,
    },
    title: "Activity",
    permissions: [[PermissionAction.Read, PermissionSubject.Transfer]],
    icon: Movement,
  },
  {
    key: "_salesforce",
    title: "Salesforce",
    icon: Salesforce,
    permissions: [],
    children: [
      {
        key: "salesforce-objects",
        route: "/salesforce-objects",
        title: "Objects",
        permissions: [[PermissionAction.Read, PermissionSubject.SalesforceObject]],
      },
    ],
  },
  {
    key: "_openplay",
    title: "OpenPlay",
    icon: OpenPlay,
    permissions: [],
    children: [
      {
        key: "openplay-objects",
        route: "/openplay-objects",
        title: "Objects",
        permissions: [[PermissionAction.Read, PermissionSubject.OpenplayObject]],
      },
      {
        key: "openplay-queries",
        route: "/openplay-queries",
        title: "Queries",
        permissions: [[PermissionAction.Read, PermissionSubject.OpenplayQuery]],
      },
    ],
  },
  {
    key: "_reports",
    title: "Reporting",
    icon: Mail,
    permissions: [],
    children: [
      {
        key: "reports",
        route: "/reports",
        title: "Reports",
        permissions: [[PermissionAction.Read, PermissionSubject.Report]],
      },
      {
        key: "email-alerts",
        route: "/email-alerts",
        title: "Email Alerts",
        permissions: [[PermissionAction.Read, PermissionSubject.EmailAlert]],
      },
      {
        key: "column-sets",
        route: "/column-sets",
        title: "Column Sets",
        permissions: [[PermissionAction.Read, PermissionSubject.SpreadsheetColumnSet]],
      },
      {
        key: "on-demand-reports",
        route: "/on-demand-reports",
        title: "On-Demand Reports",
        permissions: [[PermissionAction.Read, PermissionSubject.OnDemandReport]],
      },
    ],
  },
  {
    key: "_webhooks",
    title: "Webhooks",
    icon: Link,
    permissions: [[PermissionAction.Read, PermissionSubject.Webhook]],
    children: [
      { key: "webhooks", route: "/webhooks", title: "All Webhooks", permissions: [] },
      { key: "webhook-activity", route: "/webhook-activity", title: "Webhook Activity", permissions: [] },
    ],
  },
  {
    key: "_flows",
    title: "Flows",
    icon: Flow,
    permissions: [],
    children: [
      {
        key: "flows",
        route: "/flows",
        title: "All Flows",
        permissions: [[PermissionAction.Read, PermissionSubject.Flow]],
      },
      {
        key: "flow-events",
        route: "/flow-events",
        title: "Flow Events",
        permissions: [[PermissionAction.Read, PermissionSubject.FlowEvent]],
      },
      {
        key: "actions",
        route: "/actions",
        title: "Actions",
        permissions: [[PermissionAction.Read, PermissionSubject.Action]],
      },
    ],
  },
  {
    key: "settings",
    route: "/settings",
    title: "Settings",
    permissions: [[PermissionAction.Read, PermissionSubject.Settings]],
    icon: Gears,
  },
];

export const Sidebar = ({ collapsed }) => {
  const { pathname } = useLocation();

  const { user, ability } = useAuth();

  if (!user) {
    return null;
  }

  const activeTab = R.pipe(R.split("/"), R.filter(isNotEmpty), R.head)(pathname) as string;

  const checkVisibility = (item: MenuItem) => {
    const isVisible = item.permissions.every((permission) => ability.can(...permission));
    const childrenVisible = item.children?.length > 0 ? item.children.some(checkVisibility) : true;
    return isVisible && childrenVisible;
  };

  const items = menu.filter(checkVisibility).map(({ title, icon, route, key, children }) => {
    const visibleChildren = children?.filter(checkVisibility);
    return {
      key,
      title,
      label:
        visibleChildren?.length > 0 ? (
          <div className="menu-item">
            {/* @ts-expect-error TODO fix type errors */}
            <Icon component={icon} className="menu-item__icon" />
            <span>{title}</span>
          </div>
        ) : (
          /* @ts-expect-error TODO fix type errors */
          <LinkWithConfirmation to={route} className="menu-item">
            {/* @ts-expect-error TODO fix type errors */}
            <Icon component={icon} className="menu-item__icon" />
            <span>{title}</span>
          </LinkWithConfirmation>
        ),
      children: visibleChildren?.map((child) => ({
        key: child.key,
        title: child.title,
        /* @ts-expect-error TODO fix type errors */
        label: <LinkWithConfirmation to={child.route}>{child.title}</LinkWithConfirmation>,
      })),
    };
  });

  return (
    <Sider className="sidebar" width="var(--sidebar-width)" collapsible collapsed={collapsed} trigger={null}>
      <div className="logo">
        <Logo size={LogoSize.Small} color={LogoColor.WarmGrey} width={40} height={40} />
      </div>
      <Menu theme="dark" mode="inline" selectedKeys={[activeTab]} items={items} />
    </Sider>
  );
};
