import type { CreateFlowDto, Flow, UpdateFlowDto } from "@/api/flows/types";
import { ChangeType, FlowEventType, FlowTriggerType } from "@/api/flows/types";
import { Button, Col, Form, Input, PageHeader, Popconfirm, Radio, Row, Select } from "antd";
import { useHistory } from "react-router-dom";
import { useNavigationConfirm } from "@/utils/useNavigationConfirm";
import { useBoolean } from "ahooks";
import { capitalize, getStatusLabel, Status, statuses, stripUndefined, useAuth } from "@/utils";
import { checkFlowExistence, deleteFlow } from "@/api/flows";
import { FormActions } from "@/components/common/FormActions";
import { Box } from "@/components/common/Box/Box";
import { Field } from "@/components/common/Form/Field";
import { atLeastOne, isUniqueName, requiredIfNotDraft } from "@/utils/validation";
import { toOptions } from "@/utils/toOptions";
import { SalesforceQueryBuilder } from "@/components/common/SalesforceQuery/SalesforceQueryBuilder";
import Icon from "@ant-design/icons";
import { DropdownArrow } from "@/components/common/Icons";
import { useFlowEvents, useFlowMutation } from "@/api/flows/hooks";
import { FlowEventPickerField } from "@/components/Flows/Details/FlowEventPickerField";
import { FlowActionList } from "@/components/Flows/Details/FlowActions/FlowActionList";
import { PermissionAction, PermissionSubject } from "@/api/users/types";

const { TextArea } = Input;

type Props = {
  initialValues?: Partial<Flow>;
  isEditingByDefault?: boolean;
};

type FormValues = CreateFlowDto | UpdateFlowDto;

export const FlowForm = ({ initialValues = {}, isEditingByDefault = false }: Props) => {
  const [form] = Form.useForm<FormValues>();
  const history = useHistory();
  const { allowNavigation, preventNavigation } = useNavigationConfirm();
  const [editing, { setTrue: enableEditing, setFalse: disableEditing }] = useBoolean(isEditingByDefault);

  const handleDelete = async () => {
    if (initialValues.id) {
      await deleteFlow(initialValues.id);
      history.push(`/flows`);
    }
  };

  const handleCancel = () => {
    allowNavigation();
    if (initialValues.id) {
      form.setFieldsValue(initialValues);
      disableEditing();
    } else {
      history.push(`/flows`);
    }
  };

  const handleValueChange = () => {
    if (editing) {
      preventNavigation();
    }
  };

  const handleEdit = () => {
    enableEditing();
  };

  const { isMutating: saving, trigger: save } = useFlowMutation(initialValues.id);

  const title = initialValues.name ?? "New Flow";

  const handleFinish = async (dto: FormValues) => {
    try {
      const { id } = await save(stripUndefined(dto));
      allowNavigation();
      if (!initialValues.id) {
        history.push(`/flows/${id}`);
        return;
      }
      disableEditing();
    } catch (e) {
      console.error("unhandled error", e);
    }
  };

  const isDeletable = !!initialValues.id && !editing;

  const { isLoading: loadingFlowEvents, data: flowEvents } = useFlowEvents();

  const selectedEventName = Form.useWatch("eventName", form);

  const selectedFlowEvent = flowEvents.find((event) => event.name === selectedEventName);

  const status = Form.useWatch("status", form);
  const triggerType = Form.useWatch("triggerType", form);
  const bynderEventType = Form.useWatch("bynderEventType", form);
  const actions = Form.useWatch("actions", form);

  const cannotChangeEvent = actions?.length > 0;

  const handleTriggerTypeChange = () => {
    form.resetFields(["eventName", "changeType", "updateCriteria", "filter", "bynderEventType"]);
  };

  const { ability } = useAuth();

  const canEdit = ability.can(PermissionAction.Update, PermissionSubject.Flow);
  const canDelete = ability.can(PermissionAction.Delete, PermissionSubject.Flow);

  return (
    <>
      <PageHeader
        title={<h1 style={{ margin: 0 }}>{title}</h1>}
        extra={
          <>
            <FormActions
              isEditing={editing}
              onSave={form.submit}
              isSaving={saving}
              onEdit={handleEdit}
              onCancel={handleCancel}
              canEdit={canEdit}
            />
            {isDeletable && (
              <Popconfirm
                title="Are you sure to delete the flow?"
                onConfirm={handleDelete}
                okText="Yes"
                cancelText="No"
                disabled={!canDelete}
              >
                <Button disabled={!canDelete}>Delete</Button>
              </Popconfirm>
            )}
          </>
        }
      />
      <Form<FormValues>
        form={form}
        initialValues={initialValues}
        onFinish={handleFinish}
        validateMessages={{ required: "Field is mandatory" }}
        labelAlign="left"
        onValuesChange={handleValueChange}
      >
        <Form.Item hidden name="id">
          <Input />
        </Form.Item>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Box readOnly={!editing}>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Field
                    name="name"
                    label="Name"
                    validateTrigger={["onChange", "onBlur"]}
                    rules={[
                      { required: true, message: "Field is mandatory" },
                      isUniqueName(
                        checkFlowExistence,
                        "A flow with entered name already exists in the system"
                      ),
                    ]}
                  >
                    <Input placeholder="Flow Name" />
                  </Field>
                </Col>
                <Col span={12}>
                  <Field name="description" label="Description">
                    <TextArea placeholder="Description" rows={2} />
                  </Field>
                </Col>
                <Col span={12}>
                  <Field name="status" label="Status" initialValue={Status.Draft}>
                    <Radio.Group
                      buttonStyle="solid"
                      options={toOptions(statuses, getStatusLabel)}
                      optionType="button"
                    />
                  </Field>
                </Col>
                {status === Status.Inactive && (
                  <Col span={12}>
                    <Field name="deactivationReason" label="Deactivation Reason" rules={[requiredIfNotDraft]}>
                      <Input placeholder="Deactivation Reason" />
                    </Field>
                  </Col>
                )}
              </Row>
            </Box>
          </Col>
          <Col span={24}>
            <Box readOnly={!editing}>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Field
                    name="triggerType"
                    label="Trigger Type"
                    initialValue={FlowTriggerType.Salesforce}
                    tooltip={
                      cannotChangeEvent
                        ? "Flow trigger type cannot be changed if one or more actions are added"
                        : null
                    }
                  >
                    <Select
                      placeholder="Select trigger type"
                      options={[
                        { value: FlowTriggerType.Salesforce, label: "Salesforce" },
                        { value: FlowTriggerType.Bynder, label: "Bynder" },
                      ]}
                      suffixIcon={<Icon component={DropdownArrow} />}
                      onChange={handleTriggerTypeChange}
                      disabled={cannotChangeEvent}
                    />
                  </Field>
                </Col>
                {triggerType === FlowTriggerType.Salesforce && (
                  <>
                    <Col span={12}>
                      <FlowEventPickerField flowEvents={flowEvents} loading={loadingFlowEvents} />
                    </Col>
                    <Col span={12}>
                      {selectedFlowEvent?.type === FlowEventType.ChangeEvent && (
                        <Field name="changeType" label="Change Type" initialValue={ChangeType.Create}>
                          <Select
                            options={toOptions(Object.values(ChangeType), capitalize)}
                            suffixIcon={<Icon component={DropdownArrow} />}
                          />
                        </Field>
                      )}
                    </Col>
                    {selectedFlowEvent?.type === FlowEventType.ChangeEvent && (
                      <Col span={24}>
                        <Field
                          name="updateCriteria"
                          label="Update Criteria"
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                          rules={[
                            (form) => ({
                              ...requiredIfNotDraft(form),
                              message: "At least one of the rules should be configured",
                            }),
                          ]}
                        >
                          <SalesforceQueryBuilder from={selectedFlowEvent.entity} />
                        </Field>
                      </Col>
                    )}
                    {selectedFlowEvent?.type === FlowEventType.PlatformEvent &&
                      selectedFlowEvent?.eventObject?.fields.length > 0 && (
                        <Col span={24}>
                          <Field
                            name="filter"
                            label="Filter"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                          >
                            <SalesforceQueryBuilder from={selectedFlowEvent.eventObject.name} />
                          </Field>
                        </Col>
                      )}
                  </>
                )}
                {triggerType === FlowTriggerType.Bynder && (
                  <Col span={12}>
                    <Field
                      name="bynderEventType"
                      label="Event"
                      tooltip={
                        cannotChangeEvent
                          ? "Flow event cannot be changed if one or more actions are added"
                          : null
                      }
                    >
                      <Select
                        placeholder="Select event"
                        options={[
                          { value: "bynder.asset.created", label: "Asset: Created" },
                          // { value: "bynder.asset.metadata-updated", label: "Asset: Metadata Updated" },
                        ]}
                        suffixIcon={<Icon component={DropdownArrow} />}
                        disabled={cannotChangeEvent}
                      />
                    </Field>
                  </Col>
                )}
              </Row>
            </Box>
          </Col>
          {(selectedFlowEvent?.entity || bynderEventType) && (
            <Col span={24}>
              <Box readOnly={!editing} title="Actions">
                <FlowActionList
                  triggerType={triggerType}
                  sourceObject={selectedFlowEvent?.entity}
                  rules={
                    status !== Status.Draft ? [atLeastOne("At least one action should be added")] : undefined
                  }
                />
              </Box>
            </Col>
          )}
        </Row>
      </Form>
    </>
  );
};
