import type { ReactNode } from "react";
import { useMemo } from "react";
import { Field } from "@/components/common/Form/Field";
import "./ConditionBox.less";
import type { Fields } from "@react-awesome-query-builder/core";
import { CustomAntdConfig, ModalQueryBuilder } from "@/components/common/QueryBuilder";

type Props = {
  fields: Fields;
  children: ReactNode;
};

export const ConditionBox = ({ fields, children }: Props) => {
  const config = useMemo(() => ({ ...CustomAntdConfig, fields }), [fields]);
  return (
    <div className="condition-box">
      <div className="condition-box__condition">
        <Field
          name="condition"
          className="flex-grow"
          rules={[{ required: true, message: "At least one of the rules should be configured" }]}
        >
          <ModalQueryBuilder config={config} />
        </Field>
      </div>
      <div className="condition-box__body">{children}</div>
    </div>
  );
};
