export type FtpClientCredentials = {
  host: string;
  port: number;
  user: string;
  path?: string;
  password?: string;
};

export type SftpClientCredentials = FtpClientCredentials & {
  privateKey?: string;
  passphrase?: string;
};

export type AwsS3Credentials = {
  accessKeyId: string;
  secretAccessKey: string;
  path: string;
};

export enum StorageType {
  S3 = "s3",
  sFTP = "sftp",
  FTP = "ftp",
}

export class Storage {
  id: string;
  type: StorageType;
  name: string;
  credentials: FtpClientCredentials | SftpClientCredentials | AwsS3Credentials;
}

export type CreateStorageDto = Omit<Storage, "id">;

export type UpdateStorageDto = Pick<Storage, "id"> & Partial<Omit<Storage, "id">>;
