import type { PaginatedResponse, Pagination } from "@/api/types";
import { api } from "@/api/core";
import type {
  CreateFlowDto,
  Flow,
  FlowEvent,
  ImportPlatformEventParams,
  Action,
  ActionDescriptionUpdateDto,
  ActionFilters,
  UpdateFlowDto,
} from "./types";

export const url = "/api/flows";

export const fetchFlows = (params: Pagination) => api.get<any, PaginatedResponse<Flow>>(url, { params });

export const fetchFlow = (id: string) => api.get<any, Flow>(`${url}/${id}`);

export const createFlow = (fields: CreateFlowDto) => api.post<any, Flow>(url, fields);

export const updateFlow = (fields: UpdateFlowDto) => api.put<any, Flow>(url, fields);

export const deleteFlow = (id: Flow["id"]) => api.delete<any, void>(`${url}/${id}`);

export const saveFlow = (fields: CreateFlowDto | UpdateFlowDto) =>
  "id" in fields ? updateFlow(fields) : createFlow(fields);

export const checkFlowExistence = (name: string, excludeId: string) =>
  api.get<any, boolean>(`${url}/exists`, { params: { name, excludeId } });

export const fetchAvailableFlowEvents = () => api.get<any, FlowEvent[]>(`${url}/events`);

export const deleteFlowEvent = (name: string) => api.delete<any, void>(`${url}/events/${name}`);

export const pullChangeEvents = () => api.post<any, void>(`${url}/events/cdc`);

export const pullCustomEvent = (params: ImportPlatformEventParams) =>
  api.post<any, void>(`${url}/events/platform`, params);

export const fetchAction = (key: string) => api.get<any, Action>(`/api/actions/${key}`);

export const fetchActions = (params: ActionFilters = {}) =>
  api.get<any, Action[]>(`/api/actions`, { params });

export const updateAction = (params: ActionDescriptionUpdateDto) =>
  api.put<any, void>(`/api/actions`, params);
